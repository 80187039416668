import * as React from "react";
import { Link } from "react-router-dom";

export default class AdventureList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      adventureCollection: [
        {
          slug: "ancient-trails",
          name: "Ancient Trails",
          thumbnail: { url: "/img/3.webp" },
          duration: "6 days",
          season: "Summer",
          text: "Hike away from the crowds",
          price: "$3500/person",
          message: "Top pick"
        },
        {
          slug: "laugavegur",
          name: "Laugavegur",
          thumbnail: { url: "/img/9.webp" },
          duration: "6 days",
          season: "Summer",
          text: "Most popular hiking trail",
          price: "$3500/person"
        },
        {
          slug: "hornstrandir",
          name: "Hornstrandir",
          thumbnail: { url: "/img/6.webp" },
          duration: "7 days",
          season: "Summer",
          text: "Incredible fjords and sea cliffs",
          price: "$3500/person"
        },
        {
          slug: "fire-and-ice",
          name: "Fire and Ice",
          thumbnail: { url: "/img/1.webp" },
          duration: "7 days",
          season: "Summer",
          text: "Adventure and exploration",
          price: "$3500/person"
        },
        {
          slug: "mountain-retreat",
          name: "Mountain Retreat",
          thumbnail: { url: "/img/5.webp" },
          duration: "4 days",
          season: "All year",
          text: "Luxury in the highlands",
          price: "$3500/person"
        },
        {
          slug: "winter-wonderland",
          name: "Winter Wonderland",
          thumbnail: { url: "/img/2.webp" },
          duration: "3 days",
          season: "Winter",
          text: "Sightseeing in the winter",
          price: "$3500/person"
        },
      ],
      experienceCollection: [
        {
          slug: "golden-circle",
          name: "Golden Circle",
          thumbnail: { url: "/img/NorseAdventures_2024 6-resize-compress.jpg" },
          duration: "8 hours",
          season: "All year",
          text: "Most famous sights",
          price: "$200/person",
        },
        {
          slug: "south-coast",
          name: "South Coast",
          thumbnail: { url: "/img/5W0A0462-Edit.jpg" },
          duration: "10 hours",
          season: "All year",
          text: "Waterfalls and black beach",
          price: "$200/person"
        },
        {
          slug: "reykjanes-geopark",
          name: "Reykjanes Geopark",
          thumbnail: { url: "/img/IMG_20211102_132200.jpg" },
          duration: "6 hours",
          season: "All year",
          text: "Volcanic activity and sea cliffs",
          price: "$200/person"
        },
        {
          slug: "snaefellsnes-peninsula",
          name: "Snaefellsnes Peninsula",
          thumbnail: { url: "/img/Kirkjufell.jpg" },
          duration: "10 hours",
          season: "All year",
          text: "Iceland in a nutshell",
          price: "$200/person"
        },
        {
          slug: "thorsmork-valley-of-thor",
          name: "Thorsmork, Valley of Thor",
          thumbnail: { url: "/img/Thorsmork - View from Langidalur Hut.jpg" },
          duration: "10 hours",
          season: "All year",
          text: "River crossing and nature",
          price: "$200/person"
        },
        {
          slug: "landmannalaugar-rainbow-mountains",
          name: "Landmannalaugar, Rainbow Mountains",
          thumbnail: { url: "/img/4.webp" },
          duration: "10 hours",
          season: "All year",
          text: "Otherworldly landscapes",
          price: "$200/person"
        },
        {
          slug: "fjallabak-highland-safari",
          name: "Fjallabak, Highland Safari",
          thumbnail: { url: "/img/NorseAdventures_2024 118-resize-compress.jpg" },
          duration: "10 hours",
          season: "All year",
          text: "Explore the unknown",
          price: "$200/person"
        },
        {
          slug: "kerlingarfjoll-troll-mountains",
          name: "Kerlingarfjoll, Troll Mountains",
          thumbnail: { url: "/img/10.webp" },
          duration: "10 hours",
          season: "All year",
          text: "Spectacular colors",
          price: "$200/person"
        },
        {
          slug: "glymur-waterfall",
          name: "Glymur Waterfall",
          thumbnail: { url: "/img/315530206_1178158953116260_6639775756363418604_n.jpg" },
          duration: "6 hours",
          season: "All year",
          text: "Hike to the second tallest waterfall in Iceland",
          price: "$200/person"
        },
        {
          slug: "reykjadalur-hot-river",
          name: "Reykjadalur, Hot River",
          thumbnail: { url: "/img/NorseAdventures_2024 20-resize-compress.jpg" },
          duration: "6 hours",
          season: "All year",
          text: "Hike and bathe in a hot river",
          price: "$200/person"
        },
        {
          slug: "volcano-express",
          name: "Volcano Express",
          thumbnail: { url: "/img/received_1381277165566197.jpeg" },
          duration: "6 hours",
          season: "All year",
          text: "Hike to an active eruption",
          price: "$200/person"
        },
        {
          slug: "glacier-express",
          name: "Glacier Express",
          thumbnail: { url: "/img/483999427_686160350640921_8739487683763030487_n.jpg" },
          duration: "8 hours",
          season: "All year",
          text: "Hike on a glacier",
          price: "$200/person"
        },
        {
          slug: "ice-cave-express",
          name: "Ice Cave Express",
          thumbnail: { url: "/img/Group-Tour-Langjokull-Glacier-Tunnel-1050x700.jpg" },
          duration: "10 hours",
          season: "All year",
          text: "Go into the glacier",
          price: "$200/person"
        },
        {
          slug: "drink-with-a-viking",
          name: "Drink with a Viking",
          thumbnail: { url: "/img/pub1.jpg" },
          duration: "4 hours",
          season: "All year",
          text: "Storytelling and live music",
          price: "$200/person"
        },
        {
          slug: "northern-lights",
          name: "Northern Lights",
          thumbnail: { url: "/img/Very_Good.JPG" },
          duration: "4 hours",
          season: "Winter",
          text: "Find the aurora with us",
          price: "$200/person"
        },
        {
          slug: "blue-lagoon",
          name: "Blue Lagoon",
          thumbnail: { url: "/img/www.bluelagoon.com_travel-professionals (1).png" },
          duration: "4 hours",
          season: "All year",
          text: "Relax in healing waters",
          price: "$200/person"
        },
      ],
    };
  }

  render() {
    return (
      <div
        id="adventures"
        className="flex flex-col w-full max-w-[1408px] py-16 px-4 laptop:px-16"
      >
        <h1 className="text-3xl text-center mb-12">Multi-day Adventures</h1>
        <ul className="grid grid-cols-1 tablet:grid-cols-2 laptop:grid-cols-3 gap-8">
          {this.state.adventureCollection.length > 0 ? (
            this.state.adventureCollection.map((e) => (
                <Link to={"/adventures/" + e.slug}>
                  <li className="flex flex-col w-full aspect-[3/4] group text-white rounded-lg overflow-hidden">
                    <div className="h-full relative flex flex-col items-center">
                      <div className="relative h-full">
                        <img
                          src={e.thumbnail.url}
                          className="w-full h-full object-cover group-hover:brightness-110"
                        />
                        <img
                          className="w-full h-full absolute top-0 bg-gradient-to-t from-black/50 via-black/0 to-black/0"
                        />
                      </div>
                      {e.message && (
                          <span className="w-full bg-black/35 p-2 absolute top-0 text-center tracking-wide font-['norse'] text-3xl drop-shadow-[0_0_1px_rgba(0,0,0,1)]">{e.message}</span>
                        )}
                      <div className="absolute bottom-4 p-4 flex flex-col items-center leading-none">
                        <span className="w-full text-center tracking-wide font-['norse'] text-4xl drop-shadow-[0_0_1px_rgba(0,0,0,1)]">{e.name}</span>
                        <span className="w-full text-center text-xl drop-shadow-[0_0_1px_rgba(0,0,0,1)]">{e.text}</span>
                        <span className="w-full text-center mt-1 tracking-wide italic drop-shadow-[0_0_1px_rgba(0,0,0,1)]">{e.duration} / {e.season}</span>
                      </div>
                    </div>
                  </li>
                </Link>
              ))
          ) : (
            <span className="text-4xl col-span-full text-center">
              No Adventures Found
            </span>
          )}
        </ul>

        <h1 className="text-3xl text-center mt-12">Single-day Experiences</h1>
        <ul className="grid grid-cols-1 tablet:grid-cols-2 laptop:grid-cols-4 gap-8 mt-16">
          {this.state.experienceCollection.length > 0 ? (
            this.state.experienceCollection.map((e) => (
                <Link to={"/experience/" + e.slug}>
                  <li className="flex flex-col w-full aspect-[3/4] group text-white rounded-lg overflow-hidden">
                    <div className="h-full relative flex flex-col items-center">
                      <div className="relative h-full w-full">
                        <img
                          src={e.thumbnail.url}
                          className="w-full h-full object-cover group-hover:brightness-110"
                        />
                        <img
                          className="w-full h-full absolute top-0 bg-gradient-to-t from-black/50 via-black/0 to-black/0"
                        />
                      </div>
                      {e.message && (
                          <span className="w-full bg-black/35 p-2 absolute top-0 text-center tracking-wide font-['norse'] text-3xl drop-shadow-[0_0_1px_rgba(0,0,0,1)]">{e.message}</span>
                        )}
                      <div className="absolute bottom-4 p-4 flex flex-col items-center leading-none">
                        <span className="w-full text-center tracking-wide font-['norse'] text-4xl drop-shadow-[0_0_1px_rgba(0,0,0,1)]">{e.name}</span>
                        <span className="w-full text-center text-xl drop-shadow-[0_0_1px_rgba(0,0,0,1)]">{e.text}</span>
                        <span className="w-full text-center mt-1 tracking-wide italic drop-shadow-[0_0_1px_rgba(0,0,0,1)]">{e.duration} / {e.season}</span>
                      </div>
                    </div>
                  </li>
                </Link>
              ))
          ) : (
            <span className="text-4xl col-span-full text-center">
              No Experiences Found
            </span>
          )}
        </ul>
        <div className="w-full mt-16 h-96 relative group">
          <img
            src="/img/12.webp"
            alt="A man standing on a cliff overlooking Hrafntinnusker"
            className="w-full object-cover h-full group-hover:brightness-110 rounded-lg overflow-hidden"
          />
          <div className="absolute left-0 top-0 bottom-0 right-0 bg-black bg-opacity-20"></div>
          <div className="absolute h-full w-auto items-center justify-center top-0 left-0 flex flex-col">
            <div className="flex flex-col justify-center gap-4 w-full h-full p-4 laptop:p-16">
              <span className="text-3xl tablet:text-4xl laptop:text-5xl desktop:text-6xl text-white font-black drop-shadow-md">
                Let's plan your own custom tailored itinerary!
              </span>
              <div className="flex flex-row text-xl desktop:text-3xl font-black">
                <Link
                  to="/private-adventure"
                  className="bg-neutral-100 hover:bg-neutral-200 p-4 rounded-lg"
                >
                  PRIVATE ADVENTURE
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
